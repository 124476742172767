<template>
  <div>
    <!-- <table-menu></table-menu> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
};
</script>
